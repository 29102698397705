<template>
    <div class="card">
        <div class="card-body">
            <template v-if="!loadingBusiness && business">
                <div class="box-share">
                    <div class="icon">
                        <feather type="user" size="120" />
                    </div>
                    <div class="name">
                        {{ business.name }}
                    </div>
                    <div class="action">
                        <button class="btn btn-primary" @click="duplicate">
                             <i class="uil uil-plus"></i> Add To Businesses
                        </button>
                        <button class="btn btn-dark ml-2" @click="forget">
                            Forget
                        </button>
                    </div>
                </div>
            </template>
            <div v-else class="text-center">
                <b-spinner></b-spinner>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loadingBusiness: false,
                business: null,
            }
        },

        mounted() {

            this.getAutomation()
        },

        methods: {
            getAutomation() {
                this.loadingBusiness = true
                this.$store
                    .dispatch('business/findByUniqueId', this.$route.params.id)
                    .then((business) => {
                        this.loadingBusiness = false
                        this.business = business
                    })
                    .catch(() => {
                        this.$router.push({ name: 'agency.businesses.index' })
                        this.loadingBusiness = false
                    })
            },

            forget() {
                this.$router.push({ name: 'agency.businesses.index' })
            },

            duplicate() {
                this.$router.push({ name: 'agency.businesses.create', query: { share: this.$route.params.id } })
            }
        },
    }
</script>

<style lang="scss" scoped>
.box-share {
    text-align: center;
    padding: 50px 0px;
    .icon {
        display: inline-block;
        background: #ff694c;
        color: #fff;
        border-radius: 50%;
        padding: 26px;
    }
    .name {
        color: #394a55;
        font-weight: bold;
        font-size: 24px;
        margin: 20px 0px;
    }
}

</style>